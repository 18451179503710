import request from '@/utils/request'

// 企业认证
export function getAuthPageUrl (parameter) {
  return request({
    url: 'api/enterprise/enterpriseAuthentication/getAuthPageUrl',
    method: 'get',
    params: parameter
  })
}

// 企业认证重新认证
export function getAuthPageUrl2 (parameter) {
  return request({
    url: 'api/enterprise/enterpriseAuthentication/getAuthPageUrl2',
    method: 'get',
    params: parameter
  })
}
