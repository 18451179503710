<template>
  <div class="enterprise__page">
    <div class="page__header">
      <div class="header__center">
        <div class="left" @click="$router.push('/')"></div>
        <div class="right">
          <div class="reload" @click="handleReload">认证完成后请点击此处刷新页面</div>
          <router-link to="/" class="reback_login">返回首页</router-link>
        </div>
      </div>
    </div>
    <iframe
      :src="src"
      scrolling="hidden"
      style="width: 100%; height: 100%"
      frameborder="0"
      class="trend-container2"
      id="bdIframe"
    ></iframe>
  </div>
</template>

<script>
import { getAuthPageUrl, getAuthPageUrl2 } from '@/api/enterpriseInfor'
export default {
  name: 'Enterprise',
  data () {
    return {
      src: ''
    }
  },
  created () {
    const { reloadAuth } = this.$route.query
    const { userInfo } = this.$store.getters
    if (reloadAuth) {
      getAuthPageUrl2({
        enterpriseId: userInfo.enterpriseInfoDto?.enterpriseId
      }).then((res) => {
        this.src = res.data
      })
    } else {
      if (userInfo.enterpriseInfoDto?.status !== '1') {
        getAuthPageUrl({
          enterpriseId: userInfo.enterpriseInfoDto?.enterpriseId
        }).then((res) => {
          this.src = res.data
        })
      } else {
        this.$router.push('/')
      }
    }
  },
  methods: {
    handleReload () {
      window.location.reload()
    }
  }
}
</script>
<style lang="less">
.enterprise__page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .page__header {
    width: 100%;
    position: fixed;
    z-index: 888;
    height: 52px;
    border-bottom: 1px solid #eaeaea;
    background: #ffffff;
    .header__center {
      width: 1100px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }
    .left {
      height: 30px;
      width: 170px;
      background-image: url('../../assets/denglu/logo.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 10px;
    }
    .right {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  .reload {
    cursor: pointer;
    margin-right: 10px;
    color: #40a9ff;
  }
  .reback_login {
    cursor: pointer;
    padding: 5px 10px;
  }
}
</style>
